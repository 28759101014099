import { createApp } from 'vue'

// 引入框架
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/style/element-variables.scss'
// 中文
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

// 路由守卫
import './permission'

//pinia 状态库
import { createPinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persist' //持久化sessionStorage 
const pinia = createPinia()
pinia.use(piniaPersist)

//css
import '@/assets/style/index.scss'

import App from './App.vue'
import router from './router'

//rem 
import '@/utils/rem'

// 暗黑模式css
// import 'element-plus/theme-chalk/dark/css-vars.css'

// animate
import animated from "animate.css"

// svg
import "@/icon/index";
import SvgIcon from "@/components/svgIcon/index.vue";

//打印
import print from 'vue3-print-nb'
import vue3SeamlessScroll from 'vue3-seamless-scroll';
import elTableInfiniteScroll from 'el-table-infinite-scroll'
// 自定义指令
import * as directives from '@/directives'
const app = createApp(App)

Object.keys(directives).forEach(key => {  //Object.keys() 返回一个数组，值是所有可遍历属性的key名
    app.directive(key, directives[key])  //key是自定义指令名字；后面应该是自定义指令的值，值类型是string
})

app.use(pinia)
app.use(router)
app.use(print)
app.use(elTableInfiniteScroll)
app.use(ElementPlus, {
    locale: zhCn,
})
app.use(vue3SeamlessScroll)
app.component("svg-icon", SvgIcon)
app.mount('#app')
