<template>
  <svg
    :class="svgClass"
    aria-hidden="true"
    :style="
      props.width
        ? `width:${props.width}px;height:${props.width}px;${
            props.cursor ? 'cursor:pointer;' : ''
          }`
        : ''
    "
  >
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>
import { computed } from "@vue/reactivity";

export default {
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 0,
    },
    cursor: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    // 图标在 iconfont 中的名字
    const iconName = computed(() => {
      return `#dl-icon-${props.iconClass}`;
    });
    // 给图标添加上类名
    const svgClass = computed(() => {
      if (props.className) {
        return `svg-icon ${props.className}`;
      }
      return "svg-icon";
    });
    return {
      svgClass,
      iconName,
      props,
    };
  },
};
</script>

<style lang="scss" scoped>
.svg-icon {
  width: 30px;
  height: 30px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  transition: all 1s;
  color: #fff;
}
.big-svg-icon {
  width: 64px;
  height: 64px;
}
.svg-40 {
  width: 40px;
  height: 40px;
}
.svg-16 {
  width: 16px;
  height: 16px;
  margin-right: 12px;
  color: #273240;
}
.s-16 {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  color: $blue;
}
</style>
