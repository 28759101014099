import { ElMessage } from "element-plus";

function showToast(msg, type) {
    ElMessage({
        message: msg,
        type,
    });
}
export function successMsg(msg) {
    showToast(msg, "success");
}
export function warningMsg(msg) {
    showToast(msg, "warning");
}
export function infoMsg(msg) {
    showToast(msg, "info");
}
export function errorMsg(msg) {
    showToast(msg, "error");
}