import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import { useLoginStore } from "@/store/login.js";

function redirectF() {
  const loginStore = useLoginStore();
  const children = loginStore.getMenuList(
    dynamicRoutes,
    loginStore.menuList
  );
  let limits = loginStore.menuList
  for (let i = 0; i < children.length; i++) {
    if (limits.includes(children[i].name)) {
      console.log(children[i].path);
      return children[i].path
    }
  }
}
// 静态路由
export const routes = [
  {
    path: "/",
    component: () => import("@/layout/index.vue"),
    redirect: "/home",
    meta: { title: '首页', icon: '', },
    name: "layout",
    alwaysShow: true,
    children: [
      {
        path: "/home",
        component: () => import("@/views/home"),
        name: "home",
        meta: {
          title: "我的工作台",
          icon: "fangwuliebiao",
        },
      },
    ],
  },

  {
    path: "/login",
    name: "login",
    hidden: true,
    component: () => import("@/views/login.vue"),
    meta: { title: "登录页", icon: "" },
  },
  {
    path: "/404",
    name: "404",
    hidden: true,
    component: () => import("../views/error/404.vue"),
    meta: { title: "404", icon: "" },
  },


];
//动态路由
export const dynamicRoutes = [
  {
    path: "/custom",
    name: "custom",
    redirect: "/custom/customManage",
    meta: { title: "客户管理", icon: "kehu" },
    alwaysShow: true,
    children: [
      {
        path: "customManage",
        component: () => import("@/views/custom/customManage"),
        name: "customManage",
        meta: { title: "客户管理", icon: "kehu" },
      },
      {
        path: "customManage/add",
        component: () => import("@/views/custom/customComponent/add.vue"),
        name: "customManageAdd",
        hidden: true,
        meta: { title: "新增客户", icon: "" },
      },
      {
        path: "customManage/info",
        component: () => import("@/views/custom/customComponent/info.vue"),
        name: "customManageInfo",
        hidden: true,
        meta: { title: "客户详情", icon: "" },
      },
      {
        path: "contactsMange",
        component: () => import("@/views/custom/contactsMange"),
        name: "contactsMange",
        meta: { title: "联系人管理", icon: "lianxiren" },
      },
      {
        path: "contactsMange/add",
        component: () => import("@/views/custom/contactsComponent/add.vue"),
        name: "contactsMangeAdd",
        meta: { title: "新增联系人", icon: "" },
        hidden: true,

      },
    ],
  },
  // 收款
  {
    path: "/collection",
    name: "collection",
    redirect: "/collection/collectionNumber",
    meta: { title: "收款公司", icon: "yinhangka" },
    alwaysShow: true,
    children: [
      {
        path: "collectionNumber",
        component: () => import("@/views/collection/collectionNumber"),
        name: "collectionNumber",
        meta: { title: "收款公司", icon: "yinhangka" },
      },
      {
        path: "collectionAdd",
        component: () => import("@/views/collection/collectionAdd"),
        name: "collectionAdd",
        hidden: true,
        meta: { title: "新增收款银行", icon: "yinhangka" },
      },
      {
        path: "collectionInfo",
        component: () => import("@/views/collection/collectionInfo"),
        name: "collectionInfo",
        hidden: true,
        meta: { title: "收款公司详情", icon: "yinhangka" },
      },
    ],
  },
  //房屋
  {
    path: "/house",
    name: "house",
    redirect: "/house/houseList",
    alwaysShow: true,
    meta: { title: "房屋管理", icon: "fangwuliebiao" },
    children: [
      {
        path: "houseList",
        component: () => import("@/views/house/houseList"),
        name: "houseList",
        meta: { title: "房屋列表", icon: "fangwuliebiao" },
      },
    ],
  },
  //合同
  {
    path: "/contract",
    name: "contract",
    redirect: "/contract/contractManage",
    alwaysShow: true,
    meta: { title: "合同管理", icon: "hetongxinxi" },
    children: [
      {
        path: "contractManage",
        component: () => import("@/views/contract/contractManage"),
        name: "contractManage",
        meta: { title: "合同管理", icon: "hetongguanli" },
      },
      {
        path: "contractAdd",
        component: () => import("@/views/contract/components/contractAdd"),
        name: "contractAdd",
        hidden: true,
        meta: { title: "新增合同", icon: "" },
      },
      {
        path: "surcharge",
        component: () => import("@/views/contract/components/surcharge"),
        name: "surcharge",
        hidden: true,
        meta: { title: "附加费用", icon: "" },
      },
      {
        path: "cttRenewal",
        component: () => import("@/views/contract/components/cttRenewal"),
        name: "cttRenewal",
        hidden: true,
        meta: { title: "续签合同", icon: "" },
      },
      {
        path: "openCtt",
        component: () => import("@/views/contract/components/openCtt"),
        name: "openCtt",
        hidden: true,
        meta: { title: "打开合同", icon: "" },
      },
      {
        path: "implementation",
        component: () => import("@/views/contract/components/implementation"),
        name: "implementation",
        hidden: true,
        meta: { title: "合同执行情况", icon: "" },
      },
    ],
  },
  //费用
  {
    path: "/expense",
    name: "expense",
    redirect: "/expense/expenseDetail",
    alwaysShow: true,
    meta: { title: "费用管理", icon: "feiyongxinxi" },
    children: [
      {
        path: "expenseDetail",
        component: () => import("@/views/expense/expenseDetail"),
        name: "expenseDetail",
        meta: { title: "费用明细", icon: "feiyongmingxi" },
      },
      {
        path: "oneTime",
        component: () => import("@/views/expense/detailCom/oneTime"),
        name: "oneTime",
        hidden: true,
        meta: { title: "一次性费用录入", icon: "" },
      },
      {
        path: "openSettlement",
        component: () => import("@/views/expense/detailCom/openSettlement"),
        name: "openSettlement",
        hidden: true,
        meta: { title: "开结算单", icon: "" },
      },
      {
        path: "settleManage",
        component: () => import("@/views/expense/settleManage"),
        name: "settleManage",
        meta: { title: "结算单", icon: "feiyongxinxi" },
      },
      {
        path: "openJsList",
        component: () => import("@/views/expense/settleManageCom/openJsList"),
        name: "openJsList",
        hidden: true,
        meta: { title: "打开结算单", icon: "feiyongxinxi" },
      },

      {
        path: "expenseSummary",
        component: () => import("@/views/expense/expenseSummary"),
        name: "expenseSummary",
        meta: { title: "费用汇总", icon: "feiyonghuizong" },
      },
      {
        path: "expenseSummaryInfo",
        component: () => import("@/views/expense/expenseSummary/expenseSummaryInfo"),
        name: "expenseSummaryInfo",
        hidden: true,
        meta: { title: "费用汇总详情", icon: "" },
      },
      {
        path: "bondManage",
        component: () => import("@/views/expense/bondManage"),
        name: "bondManage",
        meta: { title: "保证金", icon: "baozhengjin" },
      },
      {
        path: "depositManage",
        component: () => import("@/views/expense/depositManage"),
        name: "depositManage",
        meta: { title: "押金管理", icon: "yajin" },
      },
      // {
      //   path: "houseManage",
      //   component: () => import("@/views/expense/houseManage"),
      //   name: "houseManage",
      //   meta: { title: "房租管理", icon: "fangzuguanli" },
      // },
      // {
      //   path: "openfzJsList",
      //   component: () => import("@/views/expense/houseManageCom/openfzJsList"),
      //   name: "openfzJsList",
      //   hidden: true,
      //   meta: { title: "打开房租结算单", icon: "" },
      // },
      // {
      //   path: "openFzSettlement",
      //   component: () => import("@/views/expense/houseManageCom/openFzSettlement"),
      //   name: "openFzSettlement",
      //   hidden: true,
      //   meta: { title: "开结算单", icon: "" },
      // },
      {
        path: "waterManage",
        component: () => import("@/views/expense/waterManage"),
        name: "waterManage",
        meta: { title: "水费管理", icon: "shuidianmingxi" },
      },
      {
        path: "electrManage",
        component: () => import("@/views/expense/electrManage"),
        name: "electrManage",
        meta: { title: "电费管理", icon: "shuidianqingsuandan" },
      },
      {
        path: "conditionManage",
        component: () => import("@/views/expense/conditionManage"),
        name: "conditionManage",
        meta: { title: "冷量费管理", icon: "fwzl" },
      },
      {
        path: "serviceFeeManage",
        component: () => import("@/views/expense/serviceFeeManage"),
        name: "serviceFeeManage",
        meta: { title: "服务费管理", icon: "fwzl" },
      },
      // {
      //   path: "sdSettlementDoc",
      //   component: () => import("@/views/expense/sdManageCom/sdSettlement"),
      //   name: "sdSettlementDoc",
      //   hidden: true,
      //   meta: { title: "开水电结算单", icon: "" },
      // },
      // {
      //   path: "sdSettlement",
      //   component: () => import("@/views/expense/sdSettlement"),
      //   name: "sdSettlement",
      //   meta: { title: "水电结算", icon: "shuidianqingsuandan" },
      // },
      // {
      //   path: "openSdSettlement",
      //   component: () => import("@/views/expense/sdSettlementCom/openSdSettlement"),
      //   name: "openSdSettlement",
      //   hidden: true,
      //   meta: { title: "打开结算单", icon: "" },
      // },
    ],
  },
  // 仓库
  {
    path: "/wareHouse",
    name: "wareHouse",
    redirect: "/wareHouse/wareHouseManage",
    alwaysShow: true,
    meta: { title: "仓库管理", icon: "cangkuguanli" },
    children: [
      {
        path: "wareHouseManage",
        component: () => import("@/views/wareHouse/wareHouseManage"),
        name: "wareHouseManage",
        meta: { title: "仓库管理", icon: "cangkuguanli" },
      },
      {
        path: "goodsManage",
        component: () => import("@/views/wareHouse/goodsManage"),
        name: "goodsManage",
        meta: { title: "商品管理", icon: "huowuguanli" },
      },
      {
        path: "goodsCategory",
        component: () => import("@/views/wareHouse/goodsCategory"),
        name: "goodsCategory",
        meta: { title: "商品类别", icon: "huowuleibie" },
      },
      {
        path: "goodsUnit",
        component: () => import("@/views/wareHouse/goodsUnit"),
        name: "goodsUnit",
        meta: { title: "商品单位", icon: "huowudanwei" },
      },
      {
        path: "wareHouseRecord",
        component: () => import("@/views/wareHouse/wareHouseRecord"),
        name: "wareHouseRecord",
        meta: { title: "出入库单", icon: "churuku" },
      },
      {
        path: "spStatistics",
        component: () => import("@/views/wareHouse/spStatistics"),
        name: "spStatistics",
        meta: { title: "商品统计", icon: "bbgl" },
      },
    ],
  },
  //报表
  // {
  //   path: "/reportForm",
  //   name: "reportForm",
  //   redirect: "/reportForm/wyybReport",
  //   alwaysShow: true,
  //   meta: { title: "报表管理", icon: "bbgl" },
  //   children: [
  //     {
  //       path: "wyybReport",
  //       component: () => import("@/views/reportForm/wyybReport"),
  //       name: "wyybReport",
  //       meta: { title: "物业收费月报表", icon: "wysfyb" },
  //     },
  //     {
  //       path: "fwybReport",
  //       component: () => import("@/views/reportForm/fwybReport"),
  //       name: "fwybReport",
  //       meta: { title: "房屋租赁月报表", icon: "fwzl" },
  //     },
  //     {
  //       path: "boneReport",
  //       component: () => import("@/views/reportForm/boneReport"),
  //       name: "boneReport",
  //       meta: { title: "保证金变更表", icon: "bzj" },
  //     },
  //     {
  //       path: "szReport",
  //       component: () => import("@/views/reportForm/szReport"),
  //       name: "szReport",
  //       meta: { title: "收支报表", icon: "sz" },
  //     },
  //     {
  //       path: "chargeReport",
  //       component: () => import("@/views/reportForm/chargeReport"),
  //       name: "chargeReport",
  //       meta: { title: "收费情况表", icon: "sfqk" },
  //     },
  //     {
  //       path: "reductionReport",
  //       component: () => import("@/views/reportForm/reductionReport"),
  //       name: "reductionReport",
  //       meta: { title: "减免费用明细表", icon: "jmfy" },
  //     },
  //   ],
  // },
  // 消息中心
  {
    path: "/message",
    name: "message",
    redirect: "/message/allMessage",
    alwaysShow: true,
    meta: { title: "消息中心", icon: "xiaoxizhongxin" },
    children: [
      {
        path: "allMessage",
        component: () => import("@/views/message/allMessage"),
        name: "allMessage",
        meta: { title: "全部消息", icon: "xiaoxizhongxin" },
      },
      {
        path: "messageInfo",
        component: () => import("@/views/message/messageInfo"),
        name: "messageInfo",
        hidden: true,
        meta: { title: "消息详情", icon: "xiaoxi" },
      },
    ],
  },
  // 系统管理
  {
    path: "/system",
    name: "system",
    redirect: "/system/userManage",
    alwaysShow: true,
    meta: { title: "系统管理", icon: "yonghugguanli" },
    children: [
      {
        path: "department",
        component: () => import("@/views/system/department"),
        name: "department",
        meta: { title: "部门管理", icon: "bumenguanli" },
      },
      {
        path: "roleManage",
        component: () => import("@/views/system/roleManage"),
        name: "roleManage",
        meta: { title: "角色管理", icon: "juesegaunli" },
      },
      {
        path: "userManage",
        component: () => import("@/views/system/userManage"),
        name: "userManage",
        meta: { title: "用户管理", icon: "yonghugguanli" },
      },
    ],
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
