import router, { routes, dynamicRoutes } from "./router";
import { useLoginStore } from "@/store/login.js";
import { extractProperty } from "@/utils/tool";

// 路由白名单
const whiteList = ['/login', '/404']
router.beforeEach(async (to, from, next) => {
    const loginStore = useLoginStore();
    // 判断用户是否登录
    const hasToken = JSON.parse(localStorage.getItem('token')) || ''
    if (hasToken) {
        // 如果用户已登录，并且跳转到登录页，则重定向到首页
        if (to.path === '/login') {
            next({ path: '/' })
        } else {
            if (loginStore.hasRoles) {
                loginStore.$patch({ hasRoles: false });
                const newMenuList = extractProperty(loginStore.menuList, "path");
                const newList = loginStore.getMenuList(
                    dynamicRoutes,
                    newMenuList
                );
                newList.forEach((item) => {
                    router.addRoute('layout', item)
                })
                // console.log(router.getRoutes());
                router.addRoute({
                    path: '/:pathMatch(.*)',
                    redirect: '/404',
                    name: 'pathMatch'
                },)

                next({ ...to })
            } else {
                next()
            }
        }
    }


    else {
        // 如果用户未登录，则判断是否在白名单中，如果在白名单中，则直接进入，否则重定向到登录页
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next(`/login`)
        }
    }
})

router.afterEach((to) => {
    // 根据路由 meta 设置标题
    const loginStore = useLoginStore();
    // document.title = to.meta.title
    if (to.path == '/login') {
        document.title = '深圳酬勤企业发展有限公司'
    } else {
        document.title = loginStore.user.projectName || process.env.VUE_APP_NAME
    }
})

// 错误处理
router.onError(() => { })