<template>
  <router-view></router-view>
</template>

<script setup >
import { useLoginStore } from "@/store/login.js";
const loginStore = useLoginStore();
loginStore.$patch({ hasRoles: true, btnRoles: true });
//解决频繁放大缩小重回报错
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  min-width: 1336px;
  overflow-x: auto;
}
</style>
