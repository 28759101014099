// 1.引入
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { login } from '@/api/login'
import { routes, dynamicRoutes } from "@/router";

// 2. 参数一  第一无二的名字
export const useLoginStore = defineStore('login', () => {
    //  Setup Store
    //     ref() 就是 state 属性
    // computed() 就是 getters
    // function() 就是 actions
    const token = ref('')
    const user = ref('')
    const buttonList = ref('') //按钮权限
    const menuList = ref('')   //路由权限
    const hasRoles = ref(true) //路由开关
    const btnRoles = ref(true) // 按钮开关
    // 登录
    const useLogin = async (v) => {
        const res = await login(v)
        if (res.code == 0) {
            token.value = res.data.token
            user.value = res.data.user
            buttonList.value = res.data.menuVo.buttonList
            menuList.value = res.data.menuVo.menuList
        }
        return res
    }

    // 处理侧边栏显示 
    const getMenuList = (dynamicRoutes, menuList) => {
        let routes = dynamicRoutes.reduce((accumulator, route) => {
            const children = route.children?.filter((child) =>
                menuList.includes(child.name)
            );
            if (children && children.length > 0) {
                accumulator.push({ ...route, children });
            }
            return accumulator;
        }, []);
        return routes
    }


    return { user, token, buttonList, menuList, hasRoles,btnRoles, useLogin, getMenuList }
}, {
    persist: {
        enabled: true, //开启缓冲 默认浏览器
        strategies: [{ storage: localStorage, }]
    }

})
//import useLoginStore form 'store/login.js'
//const useLoginStore = useLoginStore()
// 结构出来就不是响应式了    取
//import {storeToRefs} from 'pinia'    storeToRefs(useLoginStore)   解决响应式问题
//改 import {storeToRefs} from 'pinia'
// 改  直接改 useLoginStore.name = "zimo"
// 改  批量改 useLoginStore.$patch({name:"zimo",age:20})