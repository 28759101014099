import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import { ElLoading } from "element-plus";
import { errorMsg } from '@/utils/msg'
import { saveAs } from 'file-saver';
import router from '@/router/index'
let loadingCount = 0;
let loading
const startLoading = () => {
  loading = ElLoading.service({
    lock: true,
    text: '加载中……',
    background: 'rgba(0, 0, 0, 0.7)',
  })
}
const endLoading = () => {
  loading.close();
  loading = null;
};
export const showLoading = () => {
  if (loadingCount === 0 && !loading) {
    startLoading();
  }
  loadingCount += 1;
};

export const hideLoading = () => {
  loadingCount -= 1;
  loadingCount = Math.max(loadingCount, 0);
  if (loadingCount === 0 && loading) {
    endLoading();
  }
};
const request = axios.create({
  // baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 60 * 1000,
})
//请求拦截器
request.interceptors.request.use((config) => {
  if (config.url != "/api/message/top") {
    showLoading();
  }
  let hasToken = JSON.parse(localStorage.getItem('token')) || ''
  if (!hasToken) {
    hasToken = JSON.parse(localStorage.getItem('token')) || ''
  }
  if (config.headers) {
    config.headers.Authorization = hasToken || ''
  }
  return config
}, (error) => {
  hideLoading();
  return Promise.reject(error)
})
//响应拦截器
request.interceptors.response.use((response) => {
  hideLoading();
  switch (response.data.code) {
    case 10001:
      errorMsg(response.data.msg)
      localStorage.removeItem('token')
      router.push({ path: '/login' })
      break;
    case 0:
      break;
    case undefined:
      break;
    default:
      errorMsg(response.data.msg)
  }
  return response.data
}, (error) => {
  hideLoading();
  if (error && error.response && error.config.url != '/api/message/top') {
    // 1.公共错误处理
    // 2.根据响应码具体处理
    switch (error.response.status) {
      case 400:
        error.message = '错误请求'
        break;
      case 401:
        error.message = '未授权，请重新登录'
        break;
      case 403:
        error.message = '拒绝访问'
        break;
      case 404:
        error.message = '请求错误,未找到该资源'
        // window.location.href = "/NotFound"
        break;
      case 405:
        error.message = '请求方法未允许'
        break;
      case 408:
        error.message = '请求超时'
        break;
      case 500:
        error.message = '服务器端出错'
        break;
      case 501:
        error.message = '网络未实现'
        break;
      case 502:
        error.message = '网络错误'
        break;
      case 503:
        error.message = '服务不可用'
        break;
      case 504:
        error.message = '网络超时'
        break;
      case 505:
        error.message = 'http版本不支持该请求'
        break;
      default:
        error.message = `连接错误`
    }
  } else {
    // 超时处理
    if (JSON.stringify(error).includes('timeout')) {
      errorMsg('服务器响应超时，请刷新当前页')
    }
    error.message = '连接服务器失败'
  }

  errorMsg(error.message)
  return Promise.reject(error)
})
export default request

// 文件导出
export const ExportFile = (data, name) => {
  // let fileName = name + formatDate(new Date(), 'date') // 导出名字+当前日期
  let blob = new Blob([data], { type: "application/vnd.ms-excel" }) // 转成blob格式
  let link = document.createElement("a")
  // link.download = name
  const fileName = name + '.xlsx'; // 文件名，包括后缀名
  // link.href = URL.createObjectURL(blob)
  link.style.display = "none"
  document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(link.href)
  document.body.removeChild(link)
  // 使用 FileSaver 保存文件并设置文件名
  saveAs(blob, fileName);

  return
}