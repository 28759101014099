export function getNowTime() {
    var now = new Date();
    var year = now.getFullYear(); //得到年份
    var month = now.getMonth(); //得到月份
    var date = now.getDate(); //得到日期
    month = month + 1;
    month = month.toString().padStart(2, "0");
    date = date.toString().padStart(2, "0");
    return `${year}-${month}`;
}
export function getNowTimer() {
    var now = new Date();
    var year = now.getFullYear(); //得到年份
    var month = now.getMonth(); //得到月份
    var date = now.getDate(); //得到日期
    month = month + 1;
    month = month.toString().padStart(2, "0");
    date = date.toString().padStart(2, "0");
    return `${year}-${month}-${date}`;
}


// 取子集的值
export function extractProperty(array, property) {
    return array.reduce((result, item) => {
        result.push(item[property]);
        if (item.children) {
            const children = extractProperty(item.children, property);
            result.push(...children);
        }
        return result;
    }, []);
}


//  处理数字 保留两位小数
export function toFixedNum(value) {
    return Math.round(value * 100) / 100
}