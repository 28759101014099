import request from "@/utils/request";
//获取验证码
export function getCaptcha(data) {
    return request({
        url: '/api/captcha/get',
        method: 'get',
    })
}


export function login(data) {
    return request({
        url: '/api/project/login',
        method: 'post',
        data: data
    })
}

export function logout() {
    return request({
        url: '/api/project/logout',
        method: 'post',
    })
}
//修改密码
export function editPaw(data) {
    return request({
        url: '/api/project/account/updatePwd',
        method: 'put',
        data
    })
}